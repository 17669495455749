import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Dispatch, SetStateAction } from 'react';
import { styles } from '../theme';
import { colors } from '../theme/';

// Interfaces
interface InputSelectProps {
    error?: boolean;
    fields: string[];
    innerStyle?: React.CSSProperties;
    placeholder?: string;
    setValue: Dispatch<SetStateAction<string>>;
    style?: React.CSSProperties;
    value: string;
}


/**
 * Input select component
 */
export const InputSelect = ({ error, fields, innerStyle, value, placeholder, setValue, style }: InputSelectProps) => {
    // Handlers
    const handleChange = (event: SelectChangeEvent<string>) => {
        const { target: { value } } = event;

        setValue(value);
    };

    const inputSelectStyle: React.CSSProperties = {
        ...styles.inputSelect,
        ...style,
    };

    const inputSelectInnerStyle: React.CSSProperties = {
        ...styles.inputSelectInner,
        ...innerStyle,
    };

    return (
        <Select
            displayEmpty
            value={value}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
                if (selected) {
                    return selected;
                }

                return placeholder ?? "Select";
            }}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: "20vh",
                        overflow: "auto",
                        ...inputSelectInnerStyle,
                    },
                    sx: {
                        '& .MuiPaper-root': {
                            backgroundColor: colors.background.primary,
                            color: colors.font.tertiary,
                            left: 0,
                        },
                        '& .MuiList-root': {
                            paddingTop: 0,
                            paddingBottom: 0,
                        },
                        '& li:hover': {
                            backgroundColor: colors.background.success,
                            color: colors.font.tertiary,
                        },
                        '& li.Mui-selected': {
                            backgroundColor: colors.background.success,
                            color: colors.font.tertiary,
                        },
                        '& li.Mui-selected:hover': {
                            backgroundColor: colors.background.success,
                            color: colors.font.tertiary,
                        },
                    },
                },
            }}
            style={inputSelectStyle}
            sx={{
                paddingLeft: "12px",
                boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                border: error ? `1px solid ${colors.background.error}` : undefined,
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                    border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                    border: 0,
                },
                '.MuiSvgIcon-root ': {
                    fill: `${inputSelectStyle.color} !important`,
                }
            }}
        >
            {fields.map((name) => (
                <MenuItem
                    key={name}
                    value={name}
                    style={{
                        height: 50,
                    }}
                >
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
};