import { Avatar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import profilePicture from '../../medias/profilePicture.svg';
import { colors, styles } from "../../theme/";

// Interfaces
interface LoadingProps {
    show?: boolean;
}

/**
 * Loading page
 */
export const Loading = React.memo(({ show }: LoadingProps) => {
    const [loadingVisible, setLoadingVisible] = useState<boolean>(false);
    const [opacity, setOpacity] = useState<number>(1);

    useEffect(() => {
        if (show) {
            setOpacity(1);
            setLoadingVisible(true);
        } else {
            setTimeout(() => {
                setOpacity(0);
                setLoadingVisible(false);
            }, 1000);
        }
    }, [show]);

    return (
        <div style={{
            ...styles.loadingPage,
            opacity: opacity,
            pointerEvents: loadingVisible ? "auto" : "none",
            transition: "opacity 0.5s ease-in-out",
        }}>
            <Avatar
                alt="Loading"
                src={profilePicture}
                style={{
                    cursor: "pointer",
                    height: 200,
                    outline: `solid 6px ${colors.background.primary}`,
                    outlineOffset: -2,
                    position: 'absolute',
                    transform: 'translate(0%, 0%)',
                    width: 200,
                }}
            />
            <CircularProgress
                disableShrink
                size={220}
                sx={{
                    color: colors.font.secondary,
                    position: 'absolute',
                    zIndex: 1,
                }}
            />
        </div>
    );
});
