import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, GridItemContainer, InputButton } from "../../components";
import headerPicture from "../../medias/headerPicture.svg";
import profilePicture from "../../medias/profilePicture.svg";
import { colors, styles } from "../../theme";
import { Page } from "../Page/Page";
import { DiscordButton } from "./components";


const _gap = 5;
const gap = `${_gap}vw`;

export const Home = () => {
    const product = process.env.REACT_APP_STRIPE_PRODUCT_ID;

    // Hooks
    const navigate = useNavigate();

    // useStates
    const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > 1100);

    // useEffects
    window.addEventListener("resize", () => setIsDesktop(window.innerWidth > 1100));

    return (
        <Page menu innerStyle={{
            justifyContent: "flex-start",
            height: "100%",
            width: "100%",
            padding: 0,
        }}>
            <Grid container xs={12} spacing={2}>
                <GridItemContainer gap={gap} isDesktop={isDesktop} style={{

                    background: `linear-gradient(rgba(2,2,2,0.95), ${colors.background.secondary}), url(${headerPicture})`,
                    backgroundColor: colors.background.secondary,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography
                            variant="h2"
                            fontSize="max(2em, 3.2vw)"
                            style={{
                                ...styles.title,
                                zIndex: 10,
                                textShadow: "0 0 20px rgba(255,255,255,0.5)",
                            }}
                        >
                            Domine o jogo com um assistente de
                            {isDesktop ? <br /> : " "}
                            <label style={styles.label}>
                                Inteligência Artificial
                            </label>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography
                            variant="h5"
                            fontSize={"max(1em, 1.6vw)"}
                            textAlign={isDesktop ? "center" : "start"}
                            style={{ ...styles.title }}
                        >
                            Receba treinamento personalizado, sugestões de drafts,
                            dicas de matchups e muito mais, disponíveis por meio do Discord.
                        </Typography>
                    </Grid>
                    <Grid item xs={9} sm={6} md={4} xl={4} style={{ textAlign: "center" }}>
                        <DiscordButton />
                    </Grid>
                </GridItemContainer>
                <GridItemContainer gap={gap} isDesktop={isDesktop} style={{ background: undefined }}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography
                            variant="h2"
                            fontSize={"max(2em, 3.2vw)"}
                            style={styles.title}
                        >
                            Como pode ajudar?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography
                            variant="h5"
                            fontSize={"max(1em, 1.6vw)"}
                            textAlign={isDesktop ? "center" : "start"}
                            style={{ ...styles.title }}
                        >
                            Explore as jornadas que irão ajudá-lo em sua trajetória no <label style={styles.label}>League of Legend</label>.
                        </Typography>
                    </Grid>
                    <Grid item md={12} lg={3.4} style={{ textAlign: "center" }}>
                        <Card title={"Builds e matchups"} styles={{ height: "100%" }}>
                            <Typography
                                variant="body1"
                                fontSize={"max(0.8em, 1.2vw)"}
                                style={{ justifyContent: "center" }}
                            >
                                Recomendações de builds com mais ganhos para campeões e matchups.
                            </Typography>

                        </Card>
                    </Grid>
                    <Grid item md={12} lg={3.4} style={{ textAlign: "center" }}>
                        <Card title={"Melhores campeões"} styles={{ height: "100%" }}>
                            <Typography
                                variant="body1"
                                fontSize={"max(0.8em, 1.2vw)"}
                                style={{ justifyContent: "center" }}
                            >
                                Veja quais campeões estão com as melhores performances no patch atual.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={3.4} style={{ textAlign: "center" }}>
                        <Card title={"Conhecimentos gerais"} styles={{ height: "100%" }}>
                            <Typography
                                variant="body1"
                                fontSize={"max(0.8em, 1.2vw)"}
                                style={{ justifyContent: "center" }}
                            >
                                Conhecimentos gerais do jogo incluem cooldowns de habilidades, preços de itens e respawn de monstros.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={3.4} style={{ textAlign: "center" }}>
                        <Card title={"Melhores counters"} styles={{ height: "100%" }}>
                            <Typography
                                variant="body1"
                                fontSize={"max(0.8em, 1.2vw)"}
                                style={{ justifyContent: "center" }}
                            >
                                Quer saber como jogar contra um campeão específico?
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={3.4} style={{ textAlign: "center" }}>
                        <Card title={"Dicas de matchup"} styles={{ height: "100%" }}>
                            <Typography
                                variant="body1"
                                fontSize={"max(0.8em, 1.2vw)"}
                                style={{ justifyContent: "center" }}
                            >
                                Um guia para jogos fáceis e difíceis.
                            </Typography>
                        </Card>
                    </Grid>
                </GridItemContainer>
                <GridItemContainer gap={gap} isDesktop={isDesktop} style={{
                    zIndex: 10,
                    background: `linear-gradient(${colors.background.secondary}, rgba(0,0,0,0.95)), url(${headerPicture})`,
                    backgroundColor: colors.background.secondary,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography
                            variant="h2"
                            fontSize={"max(2em, 3.2vw)"}
                            style={styles.title}
                        >
                            Acessível para <label style={styles.label} >todos</label>!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography
                            variant="h5"
                            fontSize={"max(1em, 1.6vw)"}
                            textAlign={isDesktop ? "center" : "start"}
                            style={{ ...styles.title }}
                        >
                            Possibilitando o treinamento de qualidade com preços acessíveis.
                        </Typography>
                    </Grid>
                    <Grid item md={12} lg={3.5} style={{ textAlign: "center" }}>
                        <Card title={"Básico"} topTag="Grátis" >
                            <div>
                                <Typography
                                    variant="body1"
                                    fontSize={"max(0.8em, 1.2vw)"}
                                    style={{ textAlign: "start" }}
                                >
                                    Como um usuário gratuito você tem direito a 5 perguntas por dia para ajudá-lo.
                                </Typography>
                                <ul style={{
                                    textAlign: "start",
                                    fontSize: "max(0.8em, 1.2vw)",
                                }}>
                                    <li>05 perguntas / dia</li>
                                    <li>Perguntas públicas</li>
                                    <li>Sem histórico de interações</li>
                                </ul>
                            </div>
                            <div style={{ marginTop: "2.5vw", marginBottom: 10 }}>
                                <DiscordButton />
                            </div>
                        </Card>
                    </Grid>
                    <Grid item md={12} lg={6} style={{ textAlign: "center" }}>
                        <Card title={"Premium"} topTag="R$9.99 / mês" avatar={profilePicture} styles={{ boxShadow: `0 0 10px 0 ${colors.background.success}` }}>
                            <Typography
                                variant="body1"
                                fontSize={"max(0.8em, 1.2vw)"}
                                style={{ textAlign: "start" }}
                            >
                                Apoie o desenvolvimento da OP Club e tenha acesso a perguntas ilimitadas.
                            </Typography>
                            <ul style={{
                                textAlign: "start",
                                fontSize: "max(0.8em, 1.2vw)",
                            }}>
                                <li>Sem limite de perguntas</li>
                                <li>Perguntas privadas</li>
                                <li>Histórico de interações</li>
                                <li>Builds profissionais</li>
                                <li>Suporte dedicado</li>
                            </ul>
                            <div style={{ marginTop: "5vw", marginBottom: 10 }}>
                                <InputButton
                                    type={"primary"}
                                    tooltip="Seja OP!"
                                    onClick={() => navigate(`/product/${product}`)}
                                    style={{ fontSize: "max(1em, 1.6vw)" }}
                                >
                                    EU QUERO SER OP!
                                </InputButton>
                            </div>
                        </Card>
                    </Grid>
                </GridItemContainer>
            </Grid>
        </Page >
    );
};
