import { Container } from "@mui/material";
import { useMemo, useState } from "react";
import { Interaction, InteractionCycle, apiClient } from "../../../../api/";
import {
    Chat,
    Group,
    InputButton,
    InputField,
    OverlayModal,
    ShowTextField,
    showSimpleSnackbarDispatcher,
    showSnackbar,
} from "../../../../components/";
import { colors, styles } from "../../../../theme/";
import { InteractionInput } from "./InteractionInput";

// Interfaces
interface EvaluationCycleCardProps {
    interactionCycle: InteractionCycle;
    setInteractionCycles: React.Dispatch<React.SetStateAction<InteractionCycle[]>>;
}

/**
 * Evaluation cycle card component
 */
export const EvaluationCycleCard = ({ interactionCycle, setInteractionCycles }: EvaluationCycleCardProps) => {
    const {
        approver,
        evaluation,
        fixed_answer,
        fixed_question,
        interaction: {
            id: interactionId,
            answer,
            question,
        },
        status,
    } = interactionCycle;
    const borderColor = () => {
        switch (evaluation) {
            case "A": return colors.background.success;
            case "R": return colors.background.error;
            default: return colors.background.main;
        }
    };

    // useStates
    const [, setCriticalError] = useState<boolean>(false);
    const [, setError] = useState<boolean>(false);
    const [checkContextLoading, setCheckContextLoading] = useState<boolean>(false);
    const [contextModalOpen, setContextModalOpen] = useState<boolean>(false);
    const [interactions, setInteractions] = useState<Interaction[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [newFixedAnswer, setNewFixedAnswer] = useState<string>(fixed_answer ?? "");
    const [newFixedQuestion, setNewFixedQuestion] = useState<string>(fixed_question ?? "");

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // Handlers - Promotion interaction cycle
    const handlePromoteInteractionCycle = async () => {
        // Early return
        if (!token || !provider) return;

        setLoading(true);

        try {
            const [response, statusOk] = await apiClient.promoteInteractionCycle(
                token,
                provider,
                interactionId,
                newFixedAnswer,
                newFixedQuestion,
            );

            if (statusOk && response) {
                const updatedInteraction = { ...interactionCycle, status: "EBI" };

                setInteractionCycles(prev => prev.map(i => i.interaction.id === interactionId ? updatedInteraction : i));
                showSnackbar({ message: `Interaction promoted.`, variant: "success" });
            } else {
                setError(true);
                showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
            }
        } catch (error) {
            setCriticalError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }

        setLoading(false);
    };

    // Handlers - Demote interaction cycle
    const handleDemoteInteractionCycle = async () => {
        // Early return
        if (!token || !provider) return;

        setLoading(true);

        try {
            const [response, statusOk] = await apiClient.demoteInteractionCycle(
                token,
                provider,
                interactionId,
            );

            if (statusOk && response) {
                const updatedInteraction = { ...interactionCycle, status: "TBE" };

                setInteractionCycles(prev => prev.map(i => i.interaction.id === interactionId ? updatedInteraction : i));
                showSnackbar({ message: `Interaction demoted.`, variant: "success" });
            } else {
                setError(true);
                showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
            }
        } catch (error) {
            setCriticalError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }

        setLoading(false);
    };

    // Handlers - Check context
    const handleCheckContext = async () => {
        // Early return
        if (!token || !provider) return;

        setCheckContextLoading(true);

        const sessionId = interactionCycle.interaction.session;

        try {
            const [response, statusOk] = await apiClient.getInteractions(
                token,
                provider,
                sessionId
            );

            if (statusOk && response && response.result) {
                setContextModalOpen(true);
                setInteractions(response.result);
                showSnackbar({ message: `Context for session ${sessionId} opened.`, variant: "success" });
            } else {
                setError(true);
                showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
            }
        } catch (error) {
            setCriticalError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }

        setCheckContextLoading(false);
    };

    return (
        <>
            <Container style={{
                ...styles.inPlaceModal,
                borderColor: borderColor(),
                borderStyle: "solid",
                borderWidth: 2,
                maxWidth: 1080,
            }} >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                }}>
                    <InputButton
                        disabled={checkContextLoading}
                        onClick={handleCheckContext}
                        style={{ width: 200 }}
                        tooltip="Checar contexto"
                        type="main"
                    >
                        Checar contexto
                    </InputButton>
                </div>
                <hr style={{ width: "100%", borderColor: colors.background.secondary }} />
                <Group direction="row">
                    <InteractionInput title="Aprovador" innerStyle={{ height: undefined }}>
                        {approver}
                    </InteractionInput>
                    <InteractionInput title="Decisão" innerStyle={{ height: undefined }}>
                        {status === "SBA" && "Deve ser aprovado"}
                        {status === "SBD" && "Deve ser deletado"}
                        {status === "SBF" && "Deve ser consertado"}
                    </InteractionInput>
                </Group>
                {status === "SBF"
                    ? (
                        <>
                            {fixed_question
                                ? (
                                    <Group direction="row">
                                        <InteractionInput title="Input original">
                                            {question}
                                        </InteractionInput>
                                        <InteractionInput
                                            editable
                                            title="Input consertado"
                                            titleStyle={{ color: colors.font.main }}
                                            inputField={
                                                <InputField
                                                    multiline
                                                    placeholder={fixed_question}
                                                    setValue={setNewFixedQuestion}
                                                    style={{ paddingTop: 5, border: `2px solid ${colors.background.success}` }}
                                                    value={newFixedQuestion}
                                                />
                                            }
                                        />
                                    </Group>
                                )
                                : (
                                    <InteractionInput title="Input original">
                                        {question}
                                    </InteractionInput>
                                )
                            }
                            {fixed_answer
                                ? (
                                    <Group direction="row">
                                        <InteractionInput title="Output original">
                                            {answer}
                                        </InteractionInput>
                                        <InteractionInput
                                            editable
                                            title="Output consertado"
                                            titleStyle={{ color: colors.font.main }}
                                            inputField={
                                                <InputField
                                                    multiline
                                                    placeholder={fixed_answer}
                                                    setValue={setNewFixedAnswer}
                                                    style={{ paddingTop: 5, border: `2px solid ${colors.background.success}` }}
                                                    value={newFixedAnswer}
                                                />
                                            }
                                        />
                                    </Group>
                                )
                                : (
                                    <InteractionInput title="Output original">
                                        {answer}
                                    </InteractionInput>
                                )
                            }
                        </>
                    )
                    : (
                        <Group direction="row">
                            <InteractionInput title="Input original">
                                {question}
                            </InteractionInput>

                            <InteractionInput title="Output original">
                                {answer}
                            </InteractionInput>
                        </Group>
                    )
                }
                <hr style={{ width: "100%", borderColor: colors.background.secondary }} />
                {["SBA", "SBD", "SBF"].includes(status)
                    ? (
                        <Group direction="row">
                            <InputButton
                                disabled={loading}
                                onClick={handleDemoteInteractionCycle}
                                tooltip={status === "SBF" ? "Negar conserto e enviar de volta para avaliação" : "Enviar de volta para avaliação"}
                                type="secondary"
                            >
                                {status === "SBF" ? "Cancelar e negar decisão" : "Negar decisão"}
                            </InputButton>
                            <InputButton
                                disabled={loading}
                                onClick={handlePromoteInteractionCycle}
                                tooltip={status === "SBF" ? "Consertar e aprovar decisão" : "Aprovar decisão"}
                                type="primary"
                            >
                                {status === "SBF" ? "Consertar e aprovar decisão" : "Aprovar decisão"}
                            </InputButton>
                        </Group >
                    ) : <ShowTextField>Interação avaliada! Obrigado!</ShowTextField>
                }
            </Container >
            <OverlayModal
                handleClose={() => setContextModalOpen(false)}
                open={contextModalOpen}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    height: "100%",
                    justifyContent: "space-between",
                }}>
                    <Chat activeInteractionId={interactionId} interactions={interactions} setInteractions={setInteractions} />
                    <InputButton type="main" onClick={() => setContextModalOpen(false)} tooltip="Fechar contexto">Fechar</InputButton>
                </div>
            </OverlayModal>
        </>
    );
};
