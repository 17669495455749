import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export interface ReadOnlyURLSearchParams extends URLSearchParams {
    append: never;
    set: never;
    delete: never;
    sort: never;
}

export const useSearchParams = () => {
    const { search } = useLocation();

    return useMemo(
        () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
        [search]
    );
};