import styled from '@emotion/styled';
import { MaterialDesignContent } from 'notistack';
import { colors } from './colors';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: colors.background.success,
        color: colors.font.success,
        borderRadius: 10,
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: colors.background.error,
        color: colors.font.main,
        borderRadius: 10,
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: colors.background.secondary,
        color: colors.font.warning,
        borderRadius: 10,
    },
}));
