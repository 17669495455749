import { ThemeProvider, createTheme } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { CreateProfile, EvaluationCenter, GetDiscordToken, Home, InteractionCenter, Product } from './pages';
import { StyledMaterialDesignContent } from './theme/snackbar';

const theme = createTheme({
  typography: {
    fontFamily: 'PublicSansRegular, Roboto',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} Components={{
        error: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      >
        <Router>
          <Routes>
            <Route path='*' element={<Navigate to="/" />} />
            <Route path='/' element={<Home />} />
            <Route path='/createProfile' element={<CreateProfile />} />
            <Route path='/evaluationCenter' element={<EvaluationCenter />} />
            <Route path='/getDiscordToken' element={<GetDiscordToken />} />
            <Route path='/interactionCenter' element={<InteractionCenter />} />
            <Route path='/product/:product' element={<Product />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
