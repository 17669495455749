import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../../../../api/";
import {
    OverlayFixedModal,
    showComplexSnackbarDispatcher,
    showSnackbar
} from "../../../../components/";
import { styles } from "../../../../theme/";
import { ProfileMenuModalButton } from "./ProfileMenuModalButton";
import { ProfileMenuModalLink } from "./ProfileMenuModalLink";

// Interfaces
interface ProfileMenuModalProps {
    handleClose: () => void;
    hasProfile: boolean;
    isExternalApprover: boolean;
    isInternalApprover: boolean;
    open: boolean;
}

/**
 * Profile menu modal component
 */

export const ProfileMenuModal = ({ handleClose, isExternalApprover, isInternalApprover, hasProfile, open }: ProfileMenuModalProps) => {
    const product = process.env.REACT_APP_STRIPE_PRODUCT_ID;

    // Hooks
    const navigate = useNavigate();

    // useStates
    const [, setError] = useState<boolean>(false);
    const [, setCriticalError] = useState<boolean>(false);

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // Handlers
    const handleLogout = async (token: string, provider: string) => {
        try {
            const [response, statusOk] = await apiClient.logout(token, provider);

            if (statusOk) {
                const deployKey = localStorage.getItem('deployKey');

                showSnackbar({ message: `Logout successfully.`, variant: "success" });
                localStorage.clear();
                localStorage.setItem('deployKey', deployKey as string);
                await new Promise(_ => setTimeout(_, 1000));
                window.location.reload();
            } else {
                setError(true);
                showComplexSnackbarDispatcher(response as { [key: string]: string[]; });
            }

        } catch (error) {
            setCriticalError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }
    };

    return (
        <OverlayFixedModal handleClose={handleClose} open={open} style={styles.profileMenuModal}>
            {hasProfile && (
                <ProfileMenuModalButton
                    onClick={() => navigate(`/product/${product}`)}
                    style={{ borderTopWidth: 0 }}
                    innerStyle={{ fontSize: 16 }}
                >
                    Seja OP!
                </ProfileMenuModalButton>
            )}
            {isInternalApprover && (
                <ProfileMenuModalButton onClick={() => navigate("/evaluationCenter")}>
                    Centro de Avaliação
                </ProfileMenuModalButton>
            )}
            {isExternalApprover && (
                <ProfileMenuModalButton onClick={() => navigate("/interactionCenter")}>
                    Centro de Interações
                </ProfileMenuModalButton>
            )}
            {token && provider && (
                <ProfileMenuModalLink
                    type="secondary"
                    onClick={() => handleLogout(token, provider)}
                    style={{
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    Sair
                </ProfileMenuModalLink>
            )}
        </OverlayFixedModal>
    );
};