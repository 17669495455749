import { Button, Container } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { GetUserResponse } from '../../../../api/';
import {
    Group,
    InputButton,
} from '../../../../components/';
import logo from '../../../../medias/logoAlpha.png';
import { styles } from "../../../../theme/";
import { ProfileMenu } from './ProfileMenu';

// Interfaces
interface MenuProps {
    userData?: GetUserResponse | undefined;
}

/**
 * Menu component
 */
export const Menu = ({ userData }: MenuProps) => {
    const discordURL = process.env.REACT_APP_DISCORD_URL;

    // Hooks
    const navigate = useNavigate();

    // Handlers
    const handleGetDiscordToken = async () => {
        window.open(discordURL, "_self");
    };

    return (
        <Container style={styles.menu}>
            <Button onClick={() => navigate("/games")}>
                <img src={logo} style={{ width: 230 }} alt="OP Club" />
            </Button>
            {userData
                ? <ProfileMenu userData={userData} />
                : <Group direction={'row'} style={{ width: 160 }}>
                    <InputButton
                        type={'primary'}
                        style={{ height: 60 }}
                        onClick={handleGetDiscordToken}
                        tooltip="Entrar com Discord"
                    >
                        Entrar com Discord
                    </InputButton>
                </Group>
            }
        </Container>
    );
};