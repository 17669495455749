import { Grid } from "@mui/material";
import { PropsWithChildren } from "react";
import { colors } from "../theme";

// Interface
interface GridContainerProps {
    children: React.ReactNode;
    gap: string;
    isDesktop?: boolean;
    style?: React.CSSProperties;
}

export const GridItemContainer = ({
    children,
    gap,
    isDesktop,
    style,
}: PropsWithChildren<GridContainerProps>) => {
    return (
        <Grid item container
            gap={gap}
            xs={12}
            style={{
                alignContent: isDesktop ? "center" : "space-evenly",
                background: `linear-gradient(235deg, ${colors.background.quaternary} 0%, ${colors.background.secondary} 100%)`,
                boxShadow: `0 0 10px 0 ${colors.background.secondary}`,
                display: "flex",
                justifyContent: "center",
                minHeight: isDesktop ? "90vh" : undefined,
                padding: isDesktop ? gap : "20vw",
                alignItems: "stretch",
                ...style,
            }}
        >
            {children}
        </Grid>
    );
};;