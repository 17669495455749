import { Modal, Paper } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { colors } from "../theme";

// Interfaces
interface OverlayModalProps {
    handleClose: () => void;
    open: boolean;
    style?: React.CSSProperties;
}


/**
 * Overlay modal component
 */
export const OverlayModal = ({ children, handleClose, open, style }: PropsWithChildren<OverlayModalProps>) => {
    return (
        <Modal
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            disableAutoFocus
            onClose={handleClose}
            open={open}
        >
            <Paper style={{
                alignItems: "center",
                backgroundColor: colors.background.primary,
                borderRadius: 25,
                display: "flex",
                flexDirection: "column",
                gap: 25,
                left: '50%',
                padding: 50,
                position: 'absolute' as 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: 500,
                ...style,
            }}>
                {children}
            </Paper>
        </Modal >
    );
};
