import { Container, Typography } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { colors } from "../../../../theme/";

// Interfaces
interface ProfileMenuModalLinkProps {
  onClick: () => void;
  style?: React.CSSProperties;
  type?: "primary" | "secondary";
}

/**
 * Profile menu modal link component
 */
export const ProfileMenuModalLink = ({ children, onClick, style, type = "primary" }: PropsWithChildren<ProfileMenuModalLinkProps>) => {
  // useStates
  const [mouseEnter, setMouseEnter] = useState<boolean>(false);

  return (
    <Container
      onClick={onClick}
      onMouseLeave={() => setMouseEnter(false)}
      onMouseEnter={() => setMouseEnter(true)}
      style={{
        alignItems: "center",
        backgroundColor: mouseEnter
          ? type === "secondary"
            ? colors.background.error
            : colors.background.tertiary
          : type === "secondary"
            ? colors.background.quaternary
            : colors.background.quaternary,
        cursor: "pointer",
        display: "flex",
        height: 35,
        justifyContent: 'center',
        transition: 'background-color 250ms ease',
        ...style,
      }}
    >
      <Typography style={{
        color: mouseEnter
          ? type === "secondary"
            ? colors.font.main
            : colors.font.main
          : type === "secondary"
            ? colors.font.error
            : colors.font.secondary,
        fontSize: 12,
      }}
        onClick={onClick}>{children}</Typography>
    </Container>
  );
};