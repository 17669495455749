import { Radio } from "@mui/material";
import { useCallback, useEffect } from "react";
import { colors } from "../../theme/";

// Interfaces
interface CustomRadioProps {
    queryKey: string;
    selectedValues: string[];
    setSelectedParams: React.Dispatch<React.SetStateAction<{ [key: string]: string[]; } | undefined>>;
    setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
    value: string;
}

/**
 * Custom radio component
 */
export const CustomRadio = ({ queryKey, selectedValues, setSelectedParams, setSelectedValues, value }: CustomRadioProps) => {
    // Handlers
    const handleOnClick = useCallback(() => {
        setSelectedValues(prevSelectedValues => {
            if (!prevSelectedValues.includes(value)) {
                return [...prevSelectedValues, value];
            } else {
                return prevSelectedValues.filter((v) => v !== value);
            }
        });
    }, [value, setSelectedValues]);

    // useEffects
    useEffect(() => {
        setSelectedParams((prevSelectedParams) => ({
            ...prevSelectedParams,
            [queryKey]: selectedValues
        }));
    }, [queryKey, selectedValues, setSelectedParams]);

    return (
        <Radio
            checked={selectedValues.includes(value)}
            inputProps={{ 'aria-label': value }}
            name={`color-radio-button-${value}`}
            onClick={handleOnClick}
            value={value}
            sx={{
                color: colors.background.success,
                '&.Mui-checked': {
                    color: colors.background.success,
                },
            }}
        />
    );
};
