import { Divider, Paper, Typography } from "@mui/material";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaymentMethod, apiClient } from "../../../api/";
import {
    Group,
    InputButton,
    InputField,
    InputSelect,
    OverlayFixedModal,
    showComplexSnackbarDispatcher,
    showSimpleSnackbarDispatcher,
    showSnackbar,
} from "../../../components/";
import { colors, styles } from "../../../theme/";
import { upper } from "../../../utils";
import { CriticalError } from "../../CriticalError";

// Interfaces
interface SubscribeButtonProps {
    priceId: string;
    productId: string;
    productName: string;
}

interface Address {
    city?: string;
    line1?: string;
    line2?: string;
}

/**
 * Subscribe button content
 */
const SubscribeButtonContent = ({ priceId, productId, productName }: SubscribeButtonProps) => {
    // Hooks
    const elements = useElements();
    const navigate = useNavigate();
    const stripe = useStripe();

    // useStatus
    const [cardElementComplete, setCardElementComplete] = useState<boolean>(false);
    const [city, setCity] = useState<string>("");
    const [criticalError, setCriticalError] = useState<boolean>(false);
    const [enableAddCard, setEnableAddCard] = useState<boolean>(false);
    const [enableCheckout, setEnableCheckout] = useState<boolean>(false);
    const [enableSelectPaymentMethod, setEnableSelectPaymentMethod] = useState<boolean>(false);
    const [fullName, setFullName] = useState<string>("");
    const [line1, setLine1] = useState<string>("");
    const [line2, setLine2] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [paymentMethodId, setPaymentMethodId] = useState<string>("");
    const [paymentMethodLabel, setPaymentMethodLabel] = useState<string>("");
    const [paymentMethodsLabels, setPaymentMethodsLabels] = useState<{ id: string, label: string; }[]>([]);

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    const cardElement = elements?.getElement(CardElement)!;

    // useEffects - Get payment methods
    useEffect(() => {
        const getPaymentMethods = async (token: string, provider: string) => {
            try {
                const [response, statusOk] = await apiClient.getPaymentMethods(token, provider);

                if (statusOk && response.result) {
                    setPaymentMethodsLabels(response.result.map((_: PaymentMethod) => {
                        return {
                            id: _.id,
                            label: `${upper(_.brand)} **** **** **** ${_.last4} ${Number(_.exp_month) < 10 ? `0${_.exp_month}` : _.exp_month}/${_.exp_year}`,
                        };
                    }));
                } else {
                    showComplexSnackbarDispatcher(response as { [key: string]: string[]; });
                }

            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            }
        };

        if (!token || !provider) { navigate("/"); return; }

        getPaymentMethods(token, provider);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffects - Set payment methods
    useEffect(() => {
        if (paymentMethodsLabels.length < 0) setEnableAddCard(true);
        if (paymentMethodsLabels.length > 0) setPaymentMethodId(paymentMethodsLabels[0].id);
        if (paymentMethodsLabels.length > 0) setPaymentMethodLabel(paymentMethodsLabels[0].label);
    }, [paymentMethodsLabels]);

    // useEffects - Set payment method ID based on paymentMethodLabel
    useEffect(() => {
        const paymentMethod = paymentMethodsLabels.find(_ => _.label === paymentMethodLabel);

        setPaymentMethodId(paymentMethod?.id || "");
    }, [paymentMethodLabel, paymentMethodsLabels]);

    // useEffects - Set enable checkout
    useEffect(() => {
        if ((paymentMethodId || cardElementComplete) && fullName && fullName.length > 4) setEnableCheckout(true);
        else setEnableCheckout(false);
    }, [cardElementComplete, fullName, paymentMethodId]);

    // Handlers - On change set card element state
    const handleOnChange = async (event: any) => setCardElementComplete(event.complete);

    // Handlers - Add card
    const handleAddCard = async () => {
        if (!enableAddCard) {
            setPaymentMethodLabel("");
            setPaymentMethodId("");
        } else {
            if (cardElement) cardElement.clear();
            setCardElementComplete(false);
        }

        setEnableAddCard(!enableAddCard);
    };

    // Handlers - Remove card
    const handleRemoveCard = async () => {
        const deletePaymentMethod = async (token: string, provider: string, pMethodId: string) => {
            try {
                const [response, statusOk] = await apiClient.deletePaymentMethod(token, provider, pMethodId);

                if (!statusOk) {
                    showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
                    return;
                }

                showSnackbar({ message: "Card successfully removed!", variant: "success" });

            } catch (error) {
                showSnackbar({ message: String(error), variant: "error" });
            }
        };

        if (!paymentMethodId) return;
        if (!token || !provider) { navigate("/"); return; }

        deletePaymentMethod(token, provider, paymentMethodId);
    };

    // Handlers - Subscribe
    const handleSubscribe = async () => {
        const subscribe = async (token: string, provider: string, name: string, product: string, priceId: string) => {
            let pMethodId = paymentMethodId;

            try {
                if (!pMethodId) {
                    const [getUserResponse, getUserStatusOk] = await apiClient.getUser(token, provider);

                    if (!getUserStatusOk) {
                        showSimpleSnackbarDispatcher(getUserResponse as { [key: string]: string[]; });
                        return;
                    }

                    const { username: email } = getUserResponse;

                    if (!email) {
                        showSnackbar({ message: "Failed to get account email. Please try again!", variant: "error" });
                        return;
                    }

                    const address: Address = {};

                    if (city) address["city"] = city;
                    if (line1) address["line1"] = line1;
                    if (line2) address["line2"] = line2;

                    const paymentMethod = await stripe?.createPaymentMethod({
                        billing_details: {
                            address: address,
                            email: email,
                            name: name,
                        },
                        card: cardElement,
                        type: "card",
                    });

                    if (!paymentMethod || !paymentMethod.paymentMethod) {
                        showSnackbar({ message: "Card creation failed. Please try again!", variant: "error" });
                        return;
                    }

                    pMethodId = paymentMethod.paymentMethod.id;

                    setPaymentMethodId(paymentMethod.paymentMethod.id);
                }

                if (!pMethodId) {
                    showSnackbar({ message: "Please select or create a payment method!", variant: "error" });
                    return;
                }

                const [createSubscriptionResponse, createSubscriptionStatusOk] = await apiClient.createSubscription(token, provider, name, pMethodId, product, priceId);

                if (!(createSubscriptionStatusOk && createSubscriptionResponse.result && createSubscriptionResponse.result.client_secret)) {
                    showSimpleSnackbarDispatcher(createSubscriptionResponse as { [key: string]: string[]; });
                    return;
                }

                showSnackbar({ message: "Subscription successfully created!", variant: "success" });

                const clientSecret = createSubscriptionResponse.result.client_secret;
                const confirmCardPayment = await stripe?.confirmCardPayment(clientSecret);

                if (!confirmCardPayment) {
                    showSnackbar({ message: "Payment failed. Please try again!", variant: "error" });
                    return;
                }

                if (confirmCardPayment.error) {
                    showSnackbar({ message: confirmCardPayment.error.message || "Payment failed. Please try again!", variant: "error" });
                    return;
                }

                showSnackbar({ message: "Payment successful!", variant: "success" });
                window.location.reload();

            } catch (error) {
                showSnackbar({ message: String(error), variant: "error" });
            }
        };

        if (!token || !provider) { navigate("/"); return; }

        try {
            subscribe(token, provider, fullName, productId, priceId);
        } catch (error) {
            showSnackbar({ message: String(error), variant: "error" });
        }
    };

    // Early return
    if (criticalError) return <CriticalError />;

    return (
        <>
            <InputButton type="primary" onClick={() => setOpen(true)} tooltip="Assinar" style={{ padding: "40px 0px" }}>Assinar</InputButton>
            <OverlayFixedModal handleClose={() => setOpen(false)} open={open} style={styles.productModal}>
                <div style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    {!enableSelectPaymentMethod && (
                        <>
                            <Typography style={{ fontFamily: "ArchivoBlack", fontSize: 24, color: colors.font.main }} >
                                Invoice Information
                            </Typography>
                            <InputField maxLength={48} placeholder={"Nome completo"} value={fullName} setValue={setFullName} />
                            <InputField maxLength={48} placeholder={"Endereço"} value={line1} setValue={setLine1} />
                            <Group direction={"row"}>
                                <InputField maxLength={48} placeholder={"Bairro"} value={line2} setValue={setLine2} />
                                <InputField maxLength={48} placeholder={"Cidade"} value={city} setValue={setCity} />
                            </Group>
                            <Divider sx={{ bgcolor: colors.font.main, width: "100%" }} />
                        </>
                    )}
                    <InputButton
                        disabled={!fullName || !(fullName.length > 4)}
                        onClick={() => setEnableSelectPaymentMethod(!enableSelectPaymentMethod)}
                        tooltip={enableSelectPaymentMethod ? "Voltar" : "Selecionar Método de Pagamento"}
                        type={enableSelectPaymentMethod ? "secondary" : "primary"}
                    >
                        {enableSelectPaymentMethod ? "Voltar" : "Selecionar Método de Pagamento"}
                    </InputButton>

                    {enableSelectPaymentMethod && (
                        <>
                            <Divider sx={{ bgcolor: colors.font.main, width: "100%" }} />
                            <Typography style={{ fontFamily: "ArchivoBlack", fontSize: 20, color: colors.font.main }} >
                                Selecionar Método de Pagamento
                            </Typography>
                            {paymentMethodsLabels.length > 0 && !enableAddCard && (
                                <Group direction={"row"} >
                                    <InputSelect
                                        fields={paymentMethodsLabels.map(_ => _.label)}
                                        placeholder="Select a card"
                                        setValue={setPaymentMethodLabel}
                                        value={paymentMethodLabel}
                                    />
                                    {paymentMethodLabel && (
                                        <InputButton
                                            onClick={handleRemoveCard}
                                            tooltip="Delete card"
                                            type="secondary"
                                        >
                                            Remove card
                                        </InputButton>
                                    )}
                                </Group>
                            )}
                            {enableAddCard && (
                                <Paper style={styles.inputField}>
                                    <div style={{ width: "100%", padding: "0px 25px" }}>
                                        <CardElement onChange={handleOnChange} options={{ style: styles.cardElement, hidePostalCode: true }} />
                                    </div>
                                </Paper>
                            )}
                            <InputButton
                                onClick={handleAddCard}
                                tooltip={enableAddCard ? "Cancelar" : "Adicionar novo cartão"}
                                type={enableAddCard ? "secondary" : "primary"}
                            >
                                {enableAddCard ? "Cancelar" : "Adicionar novo cartão"}
                            </InputButton>
                            <InputButton
                                disabled={!enableCheckout}
                                onClick={handleSubscribe}
                                tooltip={`Assinar ${productName}`}
                                type="primary"
                            >
                                Assinar!
                            </InputButton>
                        </>
                    )}
                </div>
            </OverlayFixedModal>
        </>
    );
};

/**
 * Subscribe button wrapper component
 */
export const SubscribeButton = ({ priceId, productId, productName }: SubscribeButtonProps) => {
    const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

    // Early return
    if (!stripePublishableKey) return null;

    const stripe = loadStripe(stripePublishableKey);

    return (
        <Elements stripe={stripe}>
            <SubscribeButtonContent priceId={priceId} productId={productId} productName={productName} />
        </Elements>
    );
};