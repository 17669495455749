import { Container } from '@mui/material';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetUserResponse } from '../../api/';
import { useViewDispatcher } from '../../hooks';
import { styles } from '../../theme/';
import { CriticalError } from '../CriticalError';
import { Menu, Support } from './components/';

// Interfaces
interface PageProps {
    hideSearchField?: boolean;
    innerStyle?: React.CSSProperties;
    menu?: boolean;
    needsAuthentication?: boolean;
    needsProfile?: boolean;
    needsToBeExternalApprover?: boolean;
    needsToBeInternalApprover?: boolean;
    needsVerification?: boolean;
    revertSupportTicket?: boolean;
    style?: React.CSSProperties;
};
interface ChildProps {
    userData: GetUserResponse | undefined;
}

/**
 * Page wrapper
 */
export const Page = ({
    children,
    innerStyle,
    menu,
    needsAuthentication,
    needsProfile,
    needsToBeExternalApprover,
    needsToBeInternalApprover,
    revertSupportTicket,
    style
}: PropsWithChildren<PageProps>) => {
    // Hooks
    const [ // NOTE: The order of these variables is important
        isAuthenticated,
        hasProfile,
        isExternalApprover,
        isInternalApprover,
        loading,
        error,
        userData,
    ] = useViewDispatcher(needsAuthentication);
    const location = useLocation();
    const navigate = useNavigate();

    const deployKey = process.env.REACT_APP_DEPLOY_KEY;
    const pathname = location.pathname;

    // useMemos
    const localDeployKey = useMemo(() => localStorage.getItem("deployKey"), []);

    // useEffect - Clear local storage on new deploy
    useEffect(() => {
        if (deployKey && deployKey !== localDeployKey) {
            localStorage.clear();
            localStorage.setItem("deployKey", deployKey);
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect - Redirect when missing information
    useEffect(() => {
        if (needsAuthentication && !isAuthenticated && !loading) {
            navigate("/");
            return;
        }
        if (needsProfile && !hasProfile && !loading) {
            navigate("/createProfile");
            return;
        }
        if (
            (needsToBeExternalApprover && !isExternalApprover && !loading)
            || (needsToBeInternalApprover && !isInternalApprover && !loading)
        ) {
            navigate("/");
            return;
        }
    }, [
        hasProfile,
        isAuthenticated,
        isExternalApprover,
        isInternalApprover,
        loading,
        navigate,
        needsAuthentication,
        needsProfile,
        needsToBeExternalApprover,
        needsToBeInternalApprover
    ]);

    // Early return
    if (error) return <CriticalError />;

    // Prohibited flows
    if (isAuthenticated && pathname === "/getDiscordToken") navigate("/createProfile");
    else if (hasProfile && pathname === "/createProfile") navigate("/");

    const pageStyle: React.CSSProperties = {
        ...styles.page,
        justifyContent: menu ? "flex-start" : "center",
        ...style,
    };
    const contentStyle: React.CSSProperties = {
        ...styles.content,
        minHeight: menu ? "80vh" : undefined,
        ...innerStyle,
    };

    return (
        <div style={pageStyle}>
            {menu && <Menu userData={userData} />}
            <Container style={contentStyle}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        const childProps = child.props;
                        const userDataPropExists = Object.prototype.hasOwnProperty.call(childProps, 'userData');

                        if (userDataPropExists) {
                            const props: ChildProps = {
                                userData: userData,
                            };

                            return React.cloneElement(child, props);
                        }

                        return child;
                    }
                })}
            </Container>
            <Support revertSupportTicket={revertSupportTicket} />
        </div>
    );
};