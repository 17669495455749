import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useMemo, useState } from 'react';
import { Interaction, apiClient } from "../../api/";
import { colors } from "../../theme/";
import { capitalize } from "../../utils";
import { Group } from "../Group";
import { InputButton } from "../InputButton";
import { ShowTextField } from "../ShowTextField";
import { showSnackbar } from "../showSnackbar";
import { showSimpleSnackbarDispatcher } from "../showSnackbarDispatcher";

// Interface
interface ChatBubbleProps {
    author: string;
    interaction: Interaction;
    setInteractions: React.Dispatch<React.SetStateAction<Interaction[]>>;
}

/**
 * Chat bubble component
 * @param author a string that represents the author of the interaction
 * @param interaction an Interaction object
 * @param setInteractions a function that sets the interactions
 * @returns a React function component
 */
export const ChatBubble = ({ author, interaction, setInteractions }: ChatBubbleProps) => {
    const { answer, created, id: interactionId, on_cycle, question } = interaction;
    const isPlayer = author === "player";
    const message = isPlayer ? question : answer;
    const createdStyle: React.CSSProperties = {
        color: isPlayer ? undefined : colors.font.primary,
        fontSize: 10,
        height: "100%",
        margin: 0,
        textAlign: "right",
    };

    // useStates
    const [, setCriticalError] = useState<boolean>(false);
    const [, setError] = useState<boolean>(false);
    const [loadingSendInteractionToCycle, setLoadingSendInteractionToCycle] = useState<boolean>(false);

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // Handlers - Send interaction to cycle
    const handleSendInteractionToCycle = async (evaluation: "R" | "N" | "A") => {
        // Early return
        if (!token || !provider) return;

        setLoadingSendInteractionToCycle(true);

        try {
            const [response, statusOk] = await apiClient.sendInteractionToCycle(
                token,
                provider,
                interactionId,
                evaluation,
            );

            if (statusOk && response) {
                const updatedInteraction = { ...interaction, on_cycle: true };

                setInteractions(prev => prev.map(i => i.id === interactionId ? updatedInteraction : i));
                showSnackbar({ message: `Interaction evaluation sent.`, variant: "success" });
            } else {
                setError(true);
                showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
            }
        } catch (error) {
            setCriticalError(true);
            showSnackbar({ message: String(error), variant: "error" });
        }

        setLoadingSendInteractionToCycle(false);
    };

    return (
        <div style={{
            display: "flex",
            justifyContent: isPlayer ? "flex-end" : "flex-start",
        }}>
            <ShowTextField
                style={{
                    backgroundColor: isPlayer ? colors.background.success : colors.background.main,
                    marginTop: isPlayer ? 0 : 10,
                    paddingBottom: 10,
                    paddingTop: 10,
                    width: "90%",
                }}
                innerStyle={{
                    color: isPlayer ? colors.font.success : colors.font.main,
                    display: "flex",
                    flexDirection: "column",
                    fontSize: 14,
                    fontWeight: isPlayer ? "bold" : "normal",
                    gap: 5,
                    textAlign: "left",
                    width: "100%",
                }} >
                <b>{isPlayer ? "You" : author}:</b>
                <p style={{ margin: 0 }}>{capitalize(message)}</p>
                <Group direction="row" style={{ marginTop: 10 }}>
                    {!on_cycle && !isPlayer && (
                        <>
                            <InputButton style={{ minWidth: undefined, width: 20, height: 20, padding: 0 }} type="main" disabled={loadingSendInteractionToCycle} onClick={() => handleSendInteractionToCycle("R")} tooltip="Reject interaction">
                                <ThumbDownIcon style={{ fontSize: 14 }} />
                            </InputButton>
                            <InputButton style={{ marginLeft: 10, minWidth: undefined, width: 20, height: 20, padding: 0 }} type="main" disabled={loadingSendInteractionToCycle} onClick={() => handleSendInteractionToCycle("A")} tooltip="Approve interaction">
                                <ThumbUpIcon style={{ fontSize: 14 }} />
                            </InputButton>
                        </>
                    )}
                    <p style={createdStyle}>{created}</p>
                </Group>
            </ShowTextField>
        </div>
    );
};