import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './fonts/Archivo/Archivo-Black.ttf';
import './fonts/Archivo/Archivo-Bold.ttf';
import './fonts/Archivo/Archivo-Regular.ttf';
import './fonts/PublicSans/PublicSans-Black.ttf';
import './fonts/PublicSans/PublicSans-Bold.ttf';
import './fonts/PublicSans/PublicSans-Regular.ttf';
import './fonts/PublicSans/PublicSans-Thin.ttf';
import './index.css';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
