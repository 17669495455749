import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

// Interfaces
interface GroupProps {
    direction: "column" | "row";
    gap?: number;
    overflow?: boolean;
    style?: React.CSSProperties;
    wrap?: boolean;
}

/**
 * Group component
 */
export const Group = ({ children, direction, gap, overflow, style, wrap }: PropsWithChildren<GroupProps>) => {
    if (wrap) {
        return <Box style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: gap ?? 25,
            overflow: "overlay",
            width: "100%",
            ...style,
        }}>
            {children}
        </Box>;
    }

    if (overflow) {
        return <Box style={{
            display: "flex",
            flexDirection: direction,
            gap: gap ?? 25,
            height: direction === "column" ? "100%" : undefined,
            overflow: "auto",
            width: "100%",
            ...style,
        }}>
            {children}
        </Box>;
    }

    const validChildren = React.Children.toArray(children).filter(child => React.isValidElement(child));
    const numberOfChildren = React.Children.count(validChildren);
    const childWidth = 100 / numberOfChildren;
    const components = React.Children.map(validChildren, child => <div style={{ width: `${direction === "column" ? 100 : childWidth}%` }}>{child}</div>);

    return <Box style={{
        display: "flex",
        flexDirection: direction,
        gap: gap ?? 25,
        height: direction === "column" ? "100%" : undefined,
        width: "100%",
        ...style,
    }}>
        {components}
    </Box>;
};
