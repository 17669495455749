import { Avatar, Chip, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import gameProfilePicture from "../medias/gameProfilePicture.svg";
import headerPicture from "../medias/headerPicture.svg";
import { colors } from "../theme/colors";

// Interfaces
interface CardProps {
    avatar?: string;
    topTag?: string;
    header?: string;
    tags?: string[];
    title: string;
    styles?: React.CSSProperties;
}

/**
 * Card component
 */
export const Card = ({
    children,
    avatar,
    topTag,
    header,
    tags,
    title,
    styles,
}: PropsWithChildren<CardProps>) => {
    return (
        <div style={{
            background: colors.background.secondary,
            borderRadius: 15,
            boxShadow: `0 0 10px 0 ${colors.background.secondary}`,
            overflow: "auto",
            width: "100%",
            ...styles,
        }}>
            <div style={{
                alignItems: "center",
                backgroundColor: colors.background.secondary,
                backgroundImage: header ? `url(${header})` : `url(${headerPicture})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "column",
                height: header ? 180 : 50,
                justifyContent: "space-between",
                overflow: "hidden",
                width: "100%",
                paddingTop: topTag ? 15 : 0,
            }}>
                <div style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                }}>
                    {topTag &&
                        <Chip
                            label={topTag}
                            size="medium"
                            style={{
                                alignItems: "center",
                                backgroundColor: colors.background.quaternary,
                                color: colors.font.main,
                                fontSize: "max(0.8em, 1.2vw)",
                                fontWeight: "bold",
                                justifyContent: "flex-start",
                                marginBottom: 10,
                                marginLeft: 20,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}
                        />
                    }
                </div>
            </div>
            <div style={{
                alignItems: "center",
                backgroundColor: colors.background.primary,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <div style={{
                    alignItems: "flex-start",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    <Typography
                        fontFamily={"ArchivoBold"}
                        fontSize={"max(1em, 1.6vw)"}
                        variant="h5"
                        style={{
                            marginBottom: 15,
                            marginLeft: avatar ? 20 : 0,
                            marginTop: 15,
                            textTransform: "capitalize",
                            textAlign: avatar ? "left" : "center",
                            width: "100%",
                            padding: 5,
                        }}
                    >
                        {title}
                    </Typography>
                    <Avatar
                        alt={title}
                        src={avatar ?? gameProfilePicture}
                        style={{
                            height: 70,
                            marginBottom: -40,
                            marginRight: 20,
                            marginTop: -40,
                            outline: `solid 6px ${colors.background.primary}`,
                            outlineOffset: -2,
                            transform: 'translate(0%, 0%)',
                            width: 70,
                            display: avatar ? "block" : "none",
                            backgroundColor: colors.background.secondary,
                        }}
                    />
                </div>
            </div>
            {(tags && tags.length > 0) &&
                <div style={{
                    marginBottom: 20,
                    marginLeft: 20,
                    marginRight: 20,
                }}>
                    <div style={{
                        alignItems: "flex-start",
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: 10,
                        overflow: "overlay",
                        width: "100%",
                    }}>
                        {tags.slice(0, 3).map(tag => (
                            <Chip
                                key={tag}
                                label={tag}
                                size="small"
                                style={{
                                    alignItems: "center",
                                    backgroundColor: colors.background.error,
                                    color: colors.font.main,
                                    fontSize: "max(0.8em, 1.2vw)",
                                    fontWeight: "bold",
                                    justifyContent: "flex-start",
                                    opacity: 0.8,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                }}
                            />
                        ))}
                    </div>
                </div>
            }
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                padding: 20,
                justifyContent: "space-between",
            }}>
                {children}
            </div>
        </div >
    );
};
