import { useEffect, useMemo, useState } from "react";
import { GetUserResponse, apiClient } from "../api/";
import { showSimpleSnackbarDispatcher, showSnackbar } from "../components/";

/**
 * View dispatcher for disabling or redirecting views based on user data.
 * @param enabled - Whether or not to enable the view dispatcher.
 * @returns A tuple containing the following values:
 *    isAuthenticated - Whether or not the user is authenticated.
 *    hasProfile - Whether or not the user has a profile.
 *    isExternalApprover - Whether or not the user is an external approver.
 *    isInternalApprover - Whether or not the user is an internal approver.
 *    loading - Whether or not the view is loading.
 *    error - Whether or not the view has an error.
 *    userData - The user data.
 */
export const useViewDispatcher = (enabled: boolean = true): [
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    GetUserResponse | undefined
] => {
    // useStates
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [hasProfile, setHasProfile] = useState<boolean>(false);
    const [isExternalApprover, setIsExternalApprover] = useState<boolean>(false);
    const [isInternalApprover, setIsInternalApprover] = useState<boolean>(false);
    const [userData, setUserData] = useState<GetUserResponse | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // useEffects
    useEffect(() => {
        const getUser = async (token: string, provider: string) => {
            try {
                const [response, statusOk] = await apiClient.getUser(token, provider);

                if (statusOk) {
                    setIsAuthenticated(true);
                    setUserData(response);

                    if (response.profiles && response.profiles.length === 1) {
                        setHasProfile(true);
                        setIsExternalApprover(response.profiles[0].external_approver);
                        setIsInternalApprover(response.profiles[0].internal_approver);

                    } else {
                        setHasProfile(false);
                        setIsExternalApprover(false);
                        setIsInternalApprover(false);
                        showSnackbar({ message: "Create a profile.", variant: "success" });
                    }

                } else {
                    console.log("else (statusOk)");
                    localStorage.removeItem("token");
                    localStorage.removeItem("provider");
                    setIsAuthenticated(false);
                    setHasProfile(false);
                    setIsExternalApprover(false);
                    setIsInternalApprover(false);
                    setUserData(undefined);
                    showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
                }

                setError(false);

            } catch (error) {
                console.log("Error");
                localStorage.removeItem("token");
                localStorage.removeItem("provider");
                setIsAuthenticated(false);
                setHasProfile(false);
                setIsExternalApprover(false);
                setIsInternalApprover(false);
                setUserData(undefined);
                setError(true);
                showSnackbar({ message: String(error), variant: "error" });

            } finally {
                await new Promise(resolve => setTimeout(resolve, 1000));
                setLoading(false);
            }
        };

        if (!enabled) return;
        if (!token || !provider) return;

        getUser(token, provider);

    }, [token, provider, enabled]);

    return [
        isAuthenticated,
        hasProfile,
        isExternalApprover,
        isInternalApprover,
        loading,
        error,
        userData,
    ];
};