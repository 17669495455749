import { enqueueSnackbar } from "notistack";

interface ShowSnackbarProps {
    message: string;
    variant: "default" | "error" | "success" | "warning" | "info";
    autoHideDuration?: number;
}

export const showSnackbar = ({ message, variant, autoHideDuration }: ShowSnackbarProps) => {
    const duration = autoHideDuration
        ? autoHideDuration
        : (message.length * 100) + 2500;

    enqueueSnackbar(
        message,
        {
            autoHideDuration: duration,
            preventDuplicate: true,
            variant: variant,
        }
    );
};