import { Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { colors } from "../theme";


// Interfaces
interface ShowTextFieldProps {
    innerStyle?: React.CSSProperties;
    noWrap?: boolean;
    style?: React.CSSProperties;
}


/**
 * Show text field component
 */
export const ShowTextField = ({ children, innerStyle, noWrap, style }: PropsWithChildren<ShowTextFieldProps>) => {
    return (
        <div style={{
            alignContent: "center",
            alignItems: "center",
            border: `2px solid ${colors.background.main}`,
            borderRadius: 15,
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "center",
            padding: "20px 0px",
            width: "100%",
            ...style,
        }}>
            <Typography
                component="span"
                noWrap={noWrap}
                style={{
                    color: colors.font.main,
                    fontSize: 20,
                    padding: "0px 20px",
                    ...innerStyle
                }}
            >
                {children}
            </Typography>
        </div>
    );
};
