interface Background {
    main: string;
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    quinary: string;
    sectary: string;
    success: string;
    error: string;
    warning: string;
}

interface Font {
    main: string;
    primary: string;
    secondary: string;
    tertiary: string;
    success: string;
    error: string;
    warning: string;
}

interface Trivia {
    discord: string;
}

interface Button {
    main: {
        background: string;
        font: string;
    };
    primary: {
        background: string;
        font: string;
    };
    secondary: {
        background: string;
        font: string;
    };
    hover: {
        background: string;
        font: string;
    };
    alternativeHover: {
        background: string;
        font: string;
    };
    disabled: {
        background: string;
        font: string;
    };
    discord: {
        background: string;
        font: string;
    };
}

interface Colors {
    background: Background;
    font: Font;
    trivia: Trivia;
    button: Button;
}

const background: Background = {
    main: "#24272B",
    primary: "#1C1E22",
    secondary: "#141416",
    tertiary: "#1E2024",
    quaternary: "#21232B",
    quinary: "#353740",
    sectary: "#FFFFFF",
    success: "#5CF7BC",
    error: "#CD294A",
    warning: "#FFA700",
};

const font: Font = {
    main: "#FFFFFF",
    primary: "#747683",
    secondary: "#5CF7BC",
    tertiary: "#141416",
    success: "#1C1E22",
    error: "#CD294A",
    warning: "#FFA700",
};

const trivia: Trivia = {
    discord: "#7289da",
};

const button: Button = {
    main: {
        background: background.secondary,
        font: font.primary,
    },
    primary: {
        background: background.success,
        font: font.success,
    },
    secondary: {
        background: background.error,
        font: font.main,
    },
    hover: {
        background: background.primary,
        font: font.secondary,
    },
    alternativeHover: {
        background: background.tertiary,
        font: font.main,
    },
    disabled: {
        background: background.main,
        font: font.primary,
    },
    discord: {
        background: trivia.discord,
        font: font.tertiary,
    },
};

export const colors: Colors = {
    background: background,
    font: font,
    trivia: trivia,
    button: button
};
