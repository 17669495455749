import { useEffect, useMemo, useState } from "react";
import { InteractionCycle, apiClient } from "../../../api/";
import {
    InputButton,
    InputField,
    InputSelect,
    OverlayModal,
    ShowTextField,
} from "../../../components/";
import { Group } from "../../../components/Group";
import { showSnackbar } from "../../../components/showSnackbar";
import { showSimpleSnackbarDispatcher } from "../../../components/showSnackbarDispatcher";
import { colors } from "../../../theme/";
import { InteractionInput } from "../../EvaluationCenter/components/EvaluationCycleCard";

// Interface
interface ProposeChangeFormProps {
    interactionCycle: InteractionCycle;
    open: boolean;
    setInteractionCycles: React.Dispatch<React.SetStateAction<InteractionCycle[]>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProposeChangeForm = ({ interactionCycle, open, setInteractionCycles, setOpen }: ProposeChangeFormProps) => {
    const {
        interaction: {
            answer,
            question
        },
        status
    } = interactionCycle;

    // useStates
    const [exposureName, setExposureName] = useState<string>("Public");
    const [loading, setLoading] = useState<boolean>(false);
    const [newAnswer, setNewAnswer] = useState<string>("");
    const [newQuestion, setNewQuestion] = useState<string>("");

    // useEffects - Set new question and new answer
    useEffect(() => {
        setNewAnswer(answer);
        setNewQuestion(question);
    }, [answer, open, question]);

    // useMemo
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // Handlers - Fix it
    const handleFixIt = async () => {
        const answerIsTheSame = newAnswer === answer;
        const isPublic = exposureName === "Public";
        const questionIsTheSame = newQuestion === question;

        // Early return
        if (!newQuestion || !newAnswer) return;
        if (!token || !provider) return;
        if (questionIsTheSame && answerIsTheSame && isPublic) return;

        setLoading(true);

        const exposure = isPublic ? "PUB" : "PRV";
        const status = isPublic ? "SBF" : "EBI";

        try {
            const [response, statusOk] = await apiClient.evaluateInteractionCycle(
                token,
                provider,
                interactionCycle.interaction.id,
                status,
                newAnswer,
                newQuestion,
                exposure,
            );

            if (statusOk) {
                setInteractionCycles((prev) => prev.filter((interactionCycle) => interactionCycle.interaction.id !== response.result?.interaction.id));
                setOpen(false);
                showSnackbar({ message: "Interação avaliada! Obrigado!", variant: "success" });
            } else {
                showSimpleSnackbarDispatcher(response as { [key: string]: string[]; });
            }
        } catch (error) {
            showSnackbar({ message: String(error), variant: "error" });
        }

        setLoading(false);
    };

    return (
        <OverlayModal
            handleClose={() => setOpen(false)}
            open={open}
            style={{ width: 960 }}
        >
            <Group direction="row">
                <InteractionInput
                    editable
                    title="Input"
                    titleStyle={{ color: colors.font.main }}
                    inputField={
                        <InputField
                            placeholder={question}
                            value={newQuestion}
                            setValue={setNewQuestion}
                            style={{ paddingTop: 20 }}
                            multiline
                        />
                    }
                />
                <InteractionInput
                    editable
                    title="Output"
                    titleStyle={{ color: colors.font.main }}
                    inputField={
                        <InputField
                            placeholder={answer}
                            value={newAnswer}
                            setValue={setNewAnswer}
                            style={{ paddingTop: 20 }}
                            multiline
                        />
                    }
                />
            </Group>
            <hr style={{
                borderColor: colors.background.secondary,
                width: "100%",
            }} />
            <InteractionInput
                editable
                title="Esta interação deve ser:"
                titleStyle={{ color: colors.font.main }}
                inputField={
                    <InputSelect
                        fields={["Public", "Private"]}
                        setValue={setExposureName}
                        value={exposureName}
                    />
                }
            />
            {status === "TBE"
                ? (
                    <Group direction="row">
                        <InputButton disabled={loading} type="secondary" onClick={() => setOpen(false)} tooltip="Cancelar mudança">Abortar</InputButton>
                        <InputButton disabled={loading || !newQuestion || !newAnswer || (newQuestion === question && newAnswer === answer && exposureName === "Public")} type="primary" onClick={handleFixIt} tooltip="Propor mudança ou privacidade">Mudar!</InputButton>
                    </Group>
                )
                : <ShowTextField>Interação avaliada! Obrigado!</ShowTextField>
            }
        </OverlayModal>
    );
};