import { PropsWithChildren } from "react";
import { Group, ShowTextField } from "../../../../components/";
import { colors } from "../../../../theme/";

// Interfaces
interface InteractionTextProps {
    editable?: boolean;
    inputField?: JSX.Element;
    title: string;
    titleStyle?: React.CSSProperties;
}

/**
 * Interaction text component
 */
export const InteractionText = ({ children, editable, inputField, title, titleStyle }: PropsWithChildren<InteractionTextProps>) => {
    return (
        <Group direction="column" style={{ gap: 10 }}>
            <h3 style={{
                marginBottom: 0,
                marginLeft: 10,
                ...titleStyle,
            }}
            >
                {title}
            </h3>
            {editable
                ? inputField
                : (
                    <ShowTextField style={{
                        backgroundColor: colors.background.main,
                        height: 120,
                        overflow: "auto",
                    }}>
                        {children}
                    </ShowTextField>
                )
            }
        </Group >
    );
};