import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { InteractionCycle, apiClient } from "../../api/";
import {
    FilterCard,
    Group,
    showComplexSnackbarDispatcher,
    showSnackbar,
} from "../../components/";
import { colors } from "../../theme/";
import { objectToQueryString } from "../../utils";
import { CriticalError } from "../CriticalError";
import { Loading } from "../Loading";
import { Page } from "../Page";
import {
    InteractionCycleCard,
    ProposeChangeForm,
} from "./components/";

/**
 * Interaction center page
 */
export const InteractionCenter = () => {
    const id = "interaction-center";

    // useStates
    const [, setError] = useState<boolean>(false);
    const [criticalError, setCriticalError] = useState<boolean>(false);
    const [interactionCycleParams, setInteractionCycleParams] = useState<{ [key: string]: string[]; } | undefined>(undefined);
    const [interactionCycles, setInteractionCycles] = useState<InteractionCycle[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedInteractionCycle, setSelectedInteractionCycle] = useState<InteractionCycle | undefined>(undefined);
    const [selectedParams, setSelectedParams] = useState<{ [key: string]: string[]; } | undefined>({});

    // useMemos
    const provider = useMemo(() => localStorage.getItem("provider"), []);
    const token = useMemo(() => localStorage.getItem("token"), []);

    // useEffects - Loading effect
    useEffect(() => { setTimeout(() => setLoading(false), 1000); }, []);

    // useEffects - Get interaction cycle when selected params change
    useEffect(() => {
        if (!token || !selectedParams) return;

        const query = objectToQueryString(selectedParams as { [key: string]: string[]; });

        const getInteractionCycles = async (token: string, provider: string) => {
            try {
                const [response, statusOk] = await apiClient.getInteractionCycles(
                    token,
                    provider,
                    ["TBE"],
                    "PUB",
                    query,
                );
                const result = response.result;

                if (statusOk && result) {
                    setInteractionCycles(result);

                    const element = document.getElementById(id);

                    if (element) element.scrollIntoView({ behavior: 'smooth' });
                } else {
                    setError(true);
                    showComplexSnackbarDispatcher(response as { [key: string]: string[]; });
                }
            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            }
        };

        if (token && provider) getInteractionCycles(token, provider);

    }, [token, selectedParams, provider]);

    // useEffects - Get interaction cycle params
    useEffect(() => {

        const getInteractionCycleParams = async (token: string, provider: string) => {
            setLoading(true);

            try {
                const [response, statusOk] = await apiClient.getInteractionCycleParams(
                    token,
                    provider,
                    ["TBE"],
                    "PUB",
                );

                if (statusOk && response.result) setInteractionCycleParams(response.result);
                else {
                    setError(true);
                    setInteractionCycleParams(undefined);
                }

            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            } finally {
                await new Promise(resolve => setTimeout(resolve, 1000));
                setLoading(false);
            }
        };

        if (token && provider) {
            getInteractionCycleParams(token, provider);
        } else {
            setError(true);
            setInteractionCycleParams(undefined);
        }

    }, [provider, token]);

    // useEffects - Get interactions for the first time
    useEffect(() => {
        const getInteractionCycles = async (token: string, provider: string) => {
            try {
                const [response, statusOk] = await apiClient.getInteractionCycles(
                    token,
                    provider,
                    ["TBE"],
                    "PUB",
                );
                const result = response.result;

                if (statusOk && result) {
                    setInteractionCycles(result);
                } else {
                    setError(true);
                    showComplexSnackbarDispatcher(response as { [key: string]: string[]; });
                }
            } catch (error) {
                setCriticalError(true);
                showSnackbar({ message: String(error), variant: "error" });
            }
        };

        if (token && provider) getInteractionCycles(token, provider);

    }, [provider, token]);

    // Handlers - Should be fixed
    const handleShouldBeFixed = (interactionCycle: InteractionCycle) => {
        setOpen(true);
        setSelectedInteractionCycle(interactionCycle);
    };

    // Early return
    if (criticalError) return <CriticalError />;

    return (
        <Page
            hideSearchField
            menu
            needsAuthentication
            needsProfile
            needsToBeExternalApprover
            needsVerification
            innerStyle={{
                justifyContent: "flex-start",
                paddingBottom: 25,
                paddingTop: 25,
            }}
        >
            <Loading show={loading} />
            <Box style={{
                display: "flex",
                flexDirection: "row",
                gap: 25,
                width: "100%",
            }}>
                {interactionCycleParams && Object.keys(interactionCycleParams).length > 0 &&
                    <div style={{
                        borderColor: colors.background.quinary,
                        borderRightWidth: 10,
                        width: "18vw",
                    }}>
                        <Group direction={"column"} overflow gap={20}>
                            {Object.keys(interactionCycleParams).map(key => <FilterCard key={key} queryKey={key} queryValues={(interactionCycleParams as { [key: string]: string[]; })[key]} setSelectedParams={setSelectedParams} />)}
                        </Group>
                    </div>
                }
                <div
                    id={id}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 25,
                        justifyContent: "flex-start",
                        paddingBottom: 25,
                        paddingTop: 25,
                        width: interactionCycleParams && Object.keys(interactionCycleParams).length > 0 ? "calc(82vw - 80px)" : "100%",
                    }}
                >
                    {interactionCycles.map((interactionCycle, index) => (
                        <InteractionCycleCard
                            handleShouldBeFixed={() => handleShouldBeFixed(interactionCycle)}
                            interactionCycle={interactionCycle}
                            key={index}
                            setInteractionCycles={setInteractionCycles}
                        />
                    ))}
                    {!interactionCycles.length && !loading && (
                        <h1 style={{ color: "white", textAlign: "center" }}>Não há interações para avaliar.</h1>
                    )}
                </div>
            </Box>
            {selectedInteractionCycle && (
                <ProposeChangeForm
                    interactionCycle={selectedInteractionCycle}
                    open={open}
                    setInteractionCycles={setInteractionCycles}
                    setOpen={setOpen}
                />
            )}
        </Page>
    );
};